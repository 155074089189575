import { IconCheck } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

const Pricing = () => {
  const navigate = useNavigate();

  const priceData = {
    oneMonth: {
      title: "1 month",
      price: 29,
      description: "Perfect opportunity to test our product",
      feature1: "1 month access to all features",
      feature2: "24/7 product support",
      feature3: "Access to our community",
      button:
        "mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border shadow-sm disabled:opacity-50 disabled:pointer-events-none focus:outline-none bg-transparent border-neutral-700 text-neutral-300 hover:bg-neutral-800 focus:bg-neutral-800"

    },
    threeMonths: {
      title: "3 months",
      price: 69,
      description: "Your go-to plan for a quick start with us",
      feature1: "3 months access to all features",
      feature2: "24/7 product support",
      feature3: "Access to our community",
      button:
        "mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg bg-gradient-to-tl from-blue-600 to-violet-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none",
    },
    sixMonths: {
      title: "6 months",
      price: 149,
      description: "Great choice for a long-term commitment",
      feature1: "6 months access to all features",
      feature2: "24/7 product support",
      feature3: "Access to our community",
      button:
        "mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border shadow-sm disabled:opacity-50 disabled:pointer-events-none focus:outline-none bg-transparent border-neutral-700 text-neutral-300 hover:bg-neutral-800 focus:bg-neutral-800",
    },
    lifetime: {
      title: "Lifetime",
      price: 249,
      description: "Choose us for life without having to worry about renewals",
      feature1: "Lifetime access to all features",
      feature2: "24/7 product support",
      feature3: "Access to our community",
      button:
        "mt-5 py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-medium rounded-lg border shadow-sm disabled:opacity-50 disabled:pointer-events-none focus:outline-none bg-transparent border-neutral-700 text-neutral-300 hover:bg-neutral-800 focus:bg-neutral-800",
    },
  };

  function PriceCard(props) {
    return (
      <>
        <h4 className="font-medium text-lg text-neutral-200">{props.title}</h4>
        <span className="mt-5 font-bold text-5xl text-neutral-200">
          <span className="font-bold text-2xl">€</span>
          {props.price}
          <span className="font-bold text-2xl">.99</span>
        </span>
        <p className="mt-2 text-sm text-neutral-500">{props.description}</p>

        <ul className="mt-7 space-y-2.5 text-sm">
          <li className="flex gap-x-2">
            <IconCheck size={18} className="text-blue-600" />
            <span className="text-neutral-400">{props.feature1}</span>
          </li>

          <li className="flex gap-x-2">
            <IconCheck size={18} className="text-blue-600" />
            <span className="text-neutral-400">{props.feature2}</span>
          </li>

          <li className="flex gap-x-2">
            <IconCheck size={18} className="text-blue-600" />
            <span className="text-neutral-400">{props.feature3}</span>
          </li>
        </ul>

        <button className={props.button} onClick={() => navigate("/store")}>
          Purchase
        </button>
      </>
    );
  }

  return (
    <>
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
          <h2 className="text-2xl font-bold md:text-4xl md:leading-tight text-white">
            Pricing
          </h2>
          <p className="mt-1 text-neutral-400">
            Choose the plan that fits your needs the most and start dominating
            your games today.
          </p>
        </div>

        <div className="mt-12 grid sm:grid-cols-2 lg:grid-cols-4 gap-6 lg:items-center">
          <div className="flex flex-col border text-center rounded-xl p-8 border-neutral-800">
            <PriceCard {...priceData.oneMonth} />
          </div>

          <div className="flex flex-col text-center shadow-xl rounded-xl p-[2px] bg-gradient-to-tl from-blue-600 to-violet-600">
            <div className="flex flex-col border-2 border-transparent rounded-xl p-8 bg-gray-900">
              <p className="mb-3">
                <span className="inline-flex items-center gap-1.5 py-1.5 px-3 rounded-lg text-xs uppercase font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 text-white">
                  Most popular
                </span>
              </p>
              <PriceCard {...priceData.threeMonths} />
            </div>
          </div>
          <div className="flex flex-col border text-center rounded-xl p-8 border-neutral-800">
            <PriceCard {...priceData.sixMonths} />
          </div>

          <div className="flex flex-col border text-center rounded-xl p-8 border-neutral-800">
            <PriceCard {...priceData.lifetime} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Pricing;
