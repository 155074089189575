import { IconChevronDown, IconChevronUp } from "@tabler/icons-react";

const Questions = () => {
  return (
    <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 lg:ps-64 mx-auto">
      <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
        <h2 className="text-2xl font-bold md:text-4xl md:leading-tight text-white">
          Frequently asked questions
        </h2>
        <p className="mt-1 text-neutral-400">
          Answers to your most frequently asked questions.
        </p>
      </div>

      <div className="max-w-2xl mx-auto">
        <div className="hs-accordion-group">
          <div
            className="hs-accordion hs-accordion-active:bg-gray-100 rounded-xl p-6 hs-accordion-active:bg-white/10 active"
            id="hs-basic-with-title-and-arrow-stretched-heading-one"
          >
            <button
              className="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-start rounded-lg transition focus:outline-none text-neutral-200 hover:text-neutral-400 focus:text-neutral-400"
              aria-expanded="true"
              aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-one"
            >
              Why can't I see the ESP in-game?
              <IconChevronDown
                size={14}
                className="hs-accordion-active:hidden block shrink-0 size-5 group-hover:text-gray-500 text-neutral-400"
              />
              <IconChevronUp
                size={14}
                className="hs-accordion-active:block hidden shrink-0 size-5 group-hover:text-gray-500 text-neutral-400"
              />
            </button>
            <div
              id="hs-basic-with-title-and-arrow-stretched-collapse-one"
              className="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-one"
            >
              <p className="text-neutral-200">
                If you did everything correctly and still can't see the ESP,
                make sure your game is in fullscreen windowed mode or start your
                game using this launch option:{" "}
                <kbd className="min-h-[30px] inline-flex justify-center items-center py-1 px-1.5 bg-white border border-gray-200 font-mono text-sm text-gray-800 rounded-md dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200">
                  -borderless
                </kbd>
              </p>
            </div>
          </div>

          <div
            className="hs-accordion hs-accordion-active:bg-gray-100 rounded-xl p-6 hs-accordion-active:bg-white/10"
            id="hs-basic-with-title-and-arrow-stretched-heading-two"
          >
            <button
              className="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-start rounded-lg transition focus:outline-none text-neutral-200 hover:text-neutral-400 focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-two"
            >
              Why can't I see my USB device in the BIOS boot menu?
              <IconChevronDown
                size={14}
                className="hs-accordion-active:hidden block shrink-0 size-5 group-hover:text-gray-500 text-neutral-400"
              />
              <IconChevronUp
                size={14}
                className="hs-accordion-active:block hidden shrink-0 size-5 group-hover:text-gray-500 text-neutral-400"
              />
            </button>
            <div
              id="hs-basic-with-title-and-arrow-stretched-collapse-two"
              className="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-two"
            >
              <p className="text-neutral-200">
                Make sure to disable every single option in Windows Defender
                inside the "Device security / Core isolation" panel. You will
                have to restart your PC for the changes to take effect.
              </p>
            </div>
          </div>

          <div
            className="hs-accordion hs-accordion-active:bg-gray-100 rounded-xl p-6 hs-accordion-active:bg-white/10"
            id="hs-basic-with-title-and-arrow-stretched-heading-three"
          >
            <button
              className="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-start rounded-lg transition focus:outline-none text-neutral-200 hover:text-neutral-400 focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-three"
            >
              I did everything and the cheat isn't working. What should I do?
              <IconChevronDown
                size={14}
                className="hs-accordion-active:hidden block shrink-0 size-5 group-hover:text-gray-500 text-neutral-400"
              />
              <IconChevronUp
                size={14}
                className="hs-accordion-active:block hidden shrink-0 size-5 group-hover:text-gray-500 text-neutral-400"
              />
            </button>
            <div
              id="hs-basic-with-title-and-arrow-stretched-collapse-three"
              className="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-three"
            >
              <p className="text-neutral-200">
                First make sure you did everything the right way by checking if
                your hardware is compatible and then carefully following every
                step in the setup guide. If the issue still persists, contact an
                admin on our Discord server for further assistance.
              </p>
            </div>
          </div>

          <div
            className="hs-accordion hs-accordion-active:bg-gray-100 rounded-xl p-6 hs-accordion-active:bg-white/10"
            id="hs-basic-with-title-and-arrow-stretched-heading-four"
          >
            <button
              className="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-start rounded-lg transition focus:outline-none text-neutral-200 hover:text-neutral-400 focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-four"
            >
              Can I get a refund?
              <IconChevronDown
                size={14}
                className="hs-accordion-active:hidden block shrink-0 size-5 group-hover:text-gray-500 text-neutral-400"
              />
              <IconChevronUp
                size={14}
                className="hs-accordion-active:block hidden shrink-0 size-5 group-hover:text-gray-500 text-neutral-400"
              />
            </button>
            <div
              id="hs-basic-with-title-and-arrow-stretched-collapse-four"
              className="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-four"
            >
              <p className="text-neutral-200">
                We don't usually offer refunds once you purchased the cheat but
                in very rare cases if we deem your request reasonable you might
                be able to ask for a refund.
              </p>
            </div>
          </div>

          <div
            className="hs-accordion hs-accordion-active:bg-gray-100 rounded-xl p-6 hs-accordion-active:bg-white/10"
            id="hs-basic-with-title-and-arrow-stretched-heading-five"
          >
            <button
              className="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-start rounded-lg transition focus:outline-none text-neutral-200 hover:text-neutral-400 focus:text-neutral-400"
              aria-expanded="false"
              aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-five"
            >
              Will there be more cheats in the future?
              <IconChevronDown
                size={14}
                className="hs-accordion-active:hidden block shrink-0 size-5 group-hover:text-gray-500 text-neutral-400"
              />
              <IconChevronUp
                size={14}
                className="hs-accordion-active:block hidden shrink-0 size-5 group-hover:text-gray-500 text-neutral-400"
              />
            </button>
            <div
              id="hs-basic-with-title-and-arrow-stretched-collapse-five"
              className="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
              role="region"
              aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-five"
            >
              <p className="text-neutral-200">
                Yes! We are planning on releasing more cheats in the future so
                you can try our software on a wider variety of games.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Questions;
