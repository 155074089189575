import { IconBrandDiscordFilled } from "@tabler/icons-react";
import { useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

const discordConfig = {
  clientId: "1280135780550836275",
  redirect: `${window.location.origin}/post-sign-in`,
};


const Login = () => {
  const ctx = useOutletContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (ctx.user.hasOwnProperty("id")) {
      navigate("/subscriptions");
      return;
    }
  }, []);


  const SignInWithDiscord = async () => {
    window.location.href = `https://discord.com/api/oauth2/authorize?client_id=${discordConfig.clientId
      }&redirect_uri=${encodeURIComponent(
        discordConfig.redirect
      )}&response_type=token&scope=identify+email`;
  };

  if (!ctx.loaded) return <></>;

  return (
    <div className="flex h-full  p-2 bg-gradient-to-b from-violet-600/10 via-transparent">
      <div className="max-w-[50rem] flex flex-col justify-center items-center mx-auto size-full">
        <img src="/assets/discord.svg" width={64} />
        <div className="text-center py-10 px-4 sm:px-6 lg:px-8">
          <h1 className="block text-2xl font-bold text-white sm:text-4xl">
            Login with Discord
          </h1>
          <p className="mt-3 text-lg text-gray-300">
            Please login with your Discord account to access the dashboard and
            store page.
          </p>
          <div className="mt-5 flex flex-col justify-center items-center gap-2 sm:flex-row sm:gap-3">
            <button
              type="button"
              className="py-3 px-4 inline-flex items-center gap-x-2 text-sm rounded-lg bg-[#5865F2] text-white"
              onClick={SignInWithDiscord}
            >
              <IconBrandDiscordFilled size={18} className="mr-[-0.25rem]" />{" "}
              Sign in with Discord
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
