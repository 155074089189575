import { IconCashRegister, IconDownload, IconLock } from "@tabler/icons-react";
import { useNavigate, useOutletContext } from "react-router-dom";

const SubList = ({ thanks }) => {
  const ctx = useOutletContext();
  const navigate = useNavigate();

  return (
    <div className="w-full lg:ps-64">
      <div className="p-4 sm:p-6 space-y-4 sm:space-y-6">
        {thanks ? (
          <div className="w-full max-w-3xl mx-auto px-4 py-10 sm:px-6 lg:px-8 lg:py-14">
            <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
              <div className="flex justify-center">
                <IconCashRegister color="lightgray" size={48} />
              </div>
              <h2 className="mt-4 text-2xl font-bold md:text-4xl md:leading-tight text-white">
                Thank you!
              </h2>
              <p className="mt-4 text-neutral-400">
                Your subscription will be activated in just a few seconds.
              </p>
              <p className="mt-4">
                <div
                  className="animate-spin inline-block size-10 border-[3px] border-current border-t-transparent text-gray-400 rounded-full"
                  role="status"
                  aria-label="loading"
                >
                  <span className="sr-only">Loading...</span>
                </div>
              </p>
            </div>
          </div>
        ) : (
          <>
            {ctx.user.has_sub ? (
              <div className="block bg-gradient-to-bl from-neutral-800 via-neutral-900 to-neutral-950 border rounded-lg hover:shadow-sm focus:outline-none border-neutral-700">
                <div className="relative flex items-center overflow-hidden">
                  <img
                    className="w-32 sm:w-48 h-full absolute inset-0 object-cover rounded-s-lg"
                    src="/assets/cs2.jpeg"
                    alt="Counter-Strike 2"
                  />

                  <div className="grow p-4 ms-32 sm:ms-48">
                    <div className="min-h-24 flex flex-col justify-center">
                      <h3 className="font-semibold text-sm text-neutral-300">
                        Counter-Strike 2
                      </h3>
                      <p className="mt-1 text-sm text-neutral-500">
                        Discover our fully kernel Counter-Strike 2 cheat
                        designed for the most secure league cheating available
                        on the market.
                      </p>
                      <p className="mt-1 text-sm">
                        <span className="text-neutral-300">Valid until</span>{" "}
                        <span className="text-green-400">
                          {ctx.user.sub_ends_at >= 2000000000000
                            ? "lifetime"
                            : new Date(ctx.user.sub_ends_at).toLocaleString()}
                        </span>
                      </p>
                      <p className="mt-1">
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://eclypse.cc/api/downloads?w=exe&token=${ctx.user.token}`}
                          type="button"
                          className="mr-1 py-1 px-2 inline-flex items-center gap-x-2 text-sm font-medium rounded-md border border-transparent bg-neutral-800 text-white hover:bg-neutral-800 focus:outline-none"
                        >
                          <IconDownload size={18} /> eclypse.exe
                        </a>
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={`https://eclypse.cc/api/downloads?w=reg&token=${ctx.user.token}`}
                          type="button"
                          className="py-1 px-2 inline-flex items-center gap-x-2 text-sm font-medium rounded-md border border-transparent bg-neutral-800 text-white hover:bg-neutral-800 focus:outline-none"
                        >
                          <IconDownload size={18} /> eclypse.reg
                        </a>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="w-full max-w-3xl mx-auto px-4 py-10 sm:px-6 lg:px-8 lg:py-14">
                <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
                  <div className="flex justify-center">
                    <IconLock color="lightgray" size={48} />
                  </div>
                  <h2 className="mt-4 text-2xl font-bold md:text-4xl md:leading-tight text-white">
                    You don't have an activate subscription!
                  </h2>
                  <p className="mt-4 text-neutral-400">
                    You can easily order one by visiting our <span className="text-blue-400 hover:cursor-pointer" onClick={() => navigate("/store")}>store</span> page!
                  </p>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div >
  );
};

export default SubList;
