import "@fontsource/inter";
import "./index.css";

import "preline/dist/preline.js";

import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";

import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useNavigate,
  useOutletContext,
} from "react-router-dom";

import App from "./App";
import FAQ from "./routes/FAQ";
import Home from "./routes/Home";
import PostSignIn from "./routes/PostSignIn";
import Setup from "./routes/Setup";
import SignIn from "./routes/SignIn";
import Store from "./routes/Store";
import Subscriptions from "./routes/Subscriptions";
import Users from "./routes/Users";

const root = ReactDOM.createRoot(document.getElementById("root"));

const Guarded = () => {
  const ctx = useOutletContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (ctx.loaded && !ctx.user.hasOwnProperty("id")) {
      navigate("/sign-in");
      return;
    }
  }, [ctx]);

  if (!ctx.loaded || !ctx.user.hasOwnProperty("id")) {
    return <></>;
  }

  return <Outlet context={ctx} />;
};

const NoMatch = () => {
  return <Navigate to={"/"} />;
};

root.render(
  <BrowserRouter>
    <Routes>
      <Route element={<App />}>
        <Route path="/" element={<Home />} />
        <Route path="/sign-in" element={<SignIn />} />

        <Route element={<Guarded />}>
          <Route path="/store" element={<Store />} />
          <Route path="/subscriptions" element={<Subscriptions />} />
          <Route path="/subscriptions/thanks" element={<Subscriptions />} />
          <Route path="/users" element={<Users />} />
          <Route path="/setup" element={<Setup />} />
          <Route path="/faq" element={<FAQ />} />
        </Route>
      </Route>

      <Route path="/post-sign-in" element={<PostSignIn />} />
      <Route path="*" element={<NoMatch />} />
    </Routes>
  </BrowserRouter>
);
