import { IconBrandDiscordFilled } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";

const Banner = () => {
  const navigate = useNavigate();

  return (
    <div className="backdrop-blur-lg bg-neutral-900/60">
      <div className="max-w-[85rem] px-4 py-4 sm:px-6 lg:px-8 mx-auto">
        <div className="grid justify-center sm:grid-cols-2 sm:items-center gap-4">
          <div className="flex items-center gap-x-3 md:gap-x-5">
            <img width={48} src="/assets/logo.png" alt=""></img>

            <div className="grow">
              <p className="md:text-xl font-semibold text-neutral-200">
                Get{" "}
                <span className="bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                  eclypse.cc
                </span>{" "}
                today
              </p>
              <p className="text-sm md:text-base text-neutral-200">
                Sign up and purchase to get access.
              </p>
            </div>
          </div>

          <div className="text-center sm:text-start flex sm:justify-end sm:items-center gap-x-3 md:gap-x-4">
            <a
              href="https://discord.gg/u8bYUXG4Pz"
              target="_blank"
              className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-full disabled:opacity-50 disabled:pointer-events-none bg-[#5865F2] text-white focus:text-neutral-300"
              rel="noreferrer"
            >
              <IconBrandDiscordFilled size={18} className="mr-[-0.25rem]" />
              Join our Discord
            </a>
            <button
              className="py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-full border border-transparent bg-gradient-to-tl from-blue-600 to-violet-600 text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none"
              onClick={() => navigate("/store")}
            >
              Buy now
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
