import { useOutletContext } from "react-router-dom";
import Dashboard from "../components/Dashboard";

const Users = () => {
  const ctx = useOutletContext();

  if (!ctx.loaded) return <></>;

  return (
    <>
      <Dashboard />

      <div className="flex justify-start p-2 bg-gradient-to-b from-violet-600/10 via-transparent"></div>
    </>
  );
};

export default Users;
