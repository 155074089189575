import { IconCrosshair, IconSettings } from "@tabler/icons-react";

const Timeline = () => {
  return (
    <div className="w-full max-w-3xl mx-auto px-4 py-10 sm:px-6 lg:px-8 lg:py-14 lg:ps-64">
      <div className="mt-4">
        <div className="max-w-2xl mx-auto text-center mb-10 lg:mb-14">
          <h2 className="text-2xl font-bold md:text-4xl md:leading-tight text-white">
            How to setup the software
          </h2>
          <p className="mt-1 text-neutral-400">
            Make sure to precisely follow every step.
          </p>
        </div>

        <div>
          <div className="group relative flex gap-x-5">
            <div className="relative group-last:after:hidden after:absolute after:top-8 after:bottom-2 after:start-3 after:w-px after:-translate-x-[0.5px] after:bg-neutral-700">
              <div className="relative z-10 size-6 flex justify-center items-center">
                <IconSettings className="shrink-0 size-10 text-neutral-400" />
              </div>
            </div>
            <div className="grow pb-8 group-last:pb-0">
              <p className="font-semibold text-sm text-neutral-200">
                First time setup
              </p>

              <p className="mt-1 text-sm text-neutral-400">
                You will only have to do this process ONCE. Make sure to follow
                every single step carefully!
              </p>

              <ul className="list-disc ms-6 mt-3 space-y-1.5">
                <li className="ps-1 text-sm text-neutral-400">
                  Download the neccesary files from the subscriptions panel.
                </li>
                <li className="ps-1 text-sm text-neutral-400">
                  Run "eclypse.reg".
                </li>
                <li className="ps-1 text-sm text-neutral-400">
                  Disable every single option in Windows Defender inside the
                  "Device security / Core isolation" panel.
                </li>
                <li className="ps-1 text-sm text-neutral-400">
                  Restart your PC for the changes to take effect.
                </li>
                <li className="ps-1 text-sm text-neutral-400">
                  You are ready to enjoy our products.
                </li>
              </ul>
            </div>
          </div>
          <div className="group relative flex gap-x-5">
            <div className="relative group-last:after:hidden after:absolute after:top-8 after:bottom-2 after:start-3 after:w-px after:-translate-x-[0.5px] after:bg-neutral-700">
              <div className="relative z-10 size-6 flex justify-center items-center">
                <IconCrosshair className="shrink-0 size-10 text-neutral-400" />
              </div>
            </div>
            <div className="grow pb-8 group-last:pb-0">
              <p className="font-semibold text-sm text-neutral-200">
                Starting the cheat
              </p>

              <p className="mt-1 text-sm text-neutral-400">
                If you have followed the instructions above you can start the
                "eclypse.exe" file and you will see a window popping up which
                means the cheat has been loaded.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10 sm:mt-14">
        <h2 className="mb-3 font-medium text-neutral-200">Compatibility</h2>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
          <div className="p-4 border rounded-lg border-neutral-700">
            <img src="/assets/win10.png" width={40} alt="Windows 10" />
            <p className="font-semibold text-sm text-neutral-200">Windows 10</p>

            <h3 className="mb-1 text-xs text-neutral-400">1607 - 22H2</h3>
          </div>

          <div className="p-4 border rounded-lg border-neutral-700">
            <img src="/assets/win11.png" width={40} alt="Windows 11" />
            <p className="font-semibold text-sm text-neutral-200">Windows 11</p>

            <h3 className="mb-1 text-xs text-neutral-400">21H2 - 24H2</h3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
