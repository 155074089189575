import { IconChevronRight } from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import Banner from "./Banner";

const Hero = () => {
  const navigate = useNavigate();

  return (
    <div className="bg-gradient-to-b from-violet-600/10 via-transparent">
      <Banner />
      <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-24 space-y-8">
        <div className="flex justify-center">
          <a href="https://discord.gg/u8bYUXG4Pz" target="_blank" className="group inline-flex items-center bg-white/10 hover:bg-white/10 border border-white/10 p-1 ps-4 rounded-full shadow-md focus:outline-none focus:bg-white/10" rel="noreferrer">
            <p className="me-2 text-white text-sm">Join our Discord</p>
            <span className="group-hover:bg-white/10 py-1.5 px-2.5 flex justify-center items-center gap-x-2 rounded-full bg-white/10 font-semibold text-white text-sm">
              <IconChevronRight size={18} className="shrink-0" />
            </span>
          </a>
        </div>

        <div className="max-w-3xl text-center mx-auto">
          <h1 className="block font-medium text-gray-200 text-4xl sm:text-5xl md:text-6xl lg:text-7xl">
            Own the competition with ease.
          </h1>
        </div>

        <div className="max-w-3xl text-center mx-auto">
          <p className="text-lg text-white/70">
            eclypse.cc is a premium cheat provider that provides undetected
            cheats for popular games. Our fully kernel cheats are the most
            secure on the market and offer a wide range of features to help you
            dominate your games.
          </p>
        </div>

        <div className="text-center">
          <button
            className="inline-flex justify-center items-center gap-x-3 text-center bg-gradient-to-tl from-blue-600 to-violet-600 shadow-lg shadow-transparent hover:shadow-blue-700/50 border border-transparent text-white text-sm font-medium rounded-full focus:outline-none focus:shadow-blue-700/50 py-3 px-6"
            onClick={() => navigate("/store")}
          >
            Purchase now
            <IconChevronRight size={18} className="shrink-0" />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
