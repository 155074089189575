import { useEffect, useState } from "react";
import { useLocation, useNavigate, useOutletContext } from "react-router-dom";
import * as API from "../api";
import Dashboard from "../components/Dashboard";
import SubList from "../components/SubList";

let cancelChecks = false;

const Subscriptions = () => {
  const ctx = useOutletContext();

  const navigate = useNavigate();
  const location = useLocation();

  const [thanks, setThanks] = useState(false);

  useEffect(() => {
    const handle = async () => {
      if (!thanks && location.pathname.includes("/subscriptions/thanks")) {
        setThanks(true);

        for (let i = 0; i < 20; i++) {
          if (cancelChecks) {
            break;
          }

          await API.fetchDiscordUser(ctx.setUser);
          await new Promise(res => setTimeout(res, 1500 * (i / 5)));
        }

        setThanks(false);
        navigate("/subscriptions");
        return;
      }
    }

    handle();
  }, [location]);

  useEffect(() => {
    if (thanks && !location.pathname.includes("/subscriptions/thanks")) {
      setThanks(false);
      cancelChecks = true;
      return;
    }
  }, [location]);

  useEffect(() => {
    if (thanks && ctx.user.has_sub) {
      cancelChecks = true;
      return;
    }
  }, [thanks, ctx.user]);

  if (!ctx.loaded) return <></>;

  return (
    <>
      <Dashboard />

      <div className="flex justify-start p-2 bg-gradient-to-b from-violet-600/10 via-transparent">
        <div className="w-full h-full">
          <SubList thanks={thanks} />
        </div>
      </div>
    </>
  );
};

export default Subscriptions;
