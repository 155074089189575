import {
  IconBrandPaypalFilled,
  IconChartDots3,
  IconChevronRight,
  IconCoinBitcoinFilled,
  IconCoinFilled,
  IconCrosshair,
  IconCurrencyBitcoin,
  IconCurrencyEthereum,
  IconCurrencyLitecoin,
  IconEye,
  IconInfoCircleFilled,
  IconLock,
  IconSpeakerphone,
  IconTarget,
  IconX
} from "@tabler/icons-react";

import { HSOverlay } from "preline/preline";
import { useRef, useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";

const ppMap = [
  "BZ2ZW8TNS4RUN", // 1 month
  "Q2WVLFJMXVG7A", // 3 months
  "U84VCX5VBQRPU", // 6 months
  "FZB8HT5QUR7H2", // lifetime
];

const Products = () => {
  const ctx = useOutletContext();
  const navigate = useNavigate();

  const [infoText, setInfoText] = useState(
    "Select your preferred payment method."
  );
  const [selectedDuration, setSelectedDuration] = useState(0);

  const paypalObject = useRef(undefined);

  const purchasePayPal = async () => {
    try {
      paypalObject.current.click();
      setInfoText("Redirecting...");
      // HSOverlay.close(HSOverlay.getInstance("#hs-cookies"));
    } catch (error) { }
  };

  const redirectToSetup = () => {
    HSOverlay.close(HSOverlay.getInstance("#hs-bg-gray-on-hover-cards"));
    navigate("/setup");
  };

  const [toggle, setToggle] = useState(false);

  const productData = {
    valorant: {
      image: "/assets/valorant.webp",
      alt: "Valorant",
      availability: "Coming soon",
      title: "Valorant",
      description: "Coming soon. Stay tuned and join our Discord for updates!",
    },
    eft: {
      image: "/assets/eft.jpg",
      alt: "Escape from Tarkov",
      availability: "Coming soon",
      title: "Escape from Tarkov",
      description: "Coming soon. Stay tuned and join our Discord for updates!",
    },
  };

  function ProductCard(props) {
    return (
      <>
        <div className="group flex flex-col h-full border shadow-sm rounded-xl bg-neutral-900 border-neutral-700 shadow-neutral-700/70">
          <div className="h-52 flex flex-col justify-center items-center rounded-t-xl">
            <img
              className="h-full object-cover rounded-t-xl w-[100%]"
              src={props.image}
              alt={props.alt}
            />
          </div>
          <div className="p-4 md:p-6">
            <span className="block mb-1 text-xs font-semibold uppercase text-amber-500">
              {props.availability}
            </span>
            <h3 className="text-xl font-semibold text-neutral-300 hover:text-white">
              {props.title}
            </h3>
            <p className="mt-3 text-neutral-500">{props.description}</p>
          </div>
          <div className="mt-auto flex border-t divide-x border-neutral-700 divide-neutral-700">
            <button
              className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-es-xl bg-white shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none bg-white/10 hover:bg-white/20 border-neutral-700 text-white"
              disabled={true}
            >
              <IconInfoCircleFilled size={18} className="mr-[-0.25rem]" />
              More info
            </button>
            <button
              className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-ee-xl bg-gradient-to-tl from-blue-600 to-violet-600 shadow-md shadow-transparent hover:shadow-blue-700/50 disabled:opacity-50 disabled:pointer-events-none bg-neutral-900 border-neutral-700 text-white hover:bg-neutral-800"
              disabled={true}
            >
              <IconCoinFilled size={18} className="mr-[-0.25rem]" />
              Purchase
            </button>
          </div>
        </div>
      </>
    );
  }

  return (
    <div className="w-full lg:ps-64">
      <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
          <div className="group flex flex-col h-full border shadow-sm rounded-xl bg-neutral-900 border-neutral-700 shadow-neutral-700/70">
            <div className="h-52 flex flex-col justify-center items-center rounded-t-xl">
              <img
                className="h-full object-cover rounded-t-xl w-[100%]"
                src="/assets/cs2.jpeg"
                alt="Counter-Strike 2"
              />
            </div>
            <div className="p-4 md:p-6">
              <span className="block mb-1 text-xs font-semibold uppercase text-green-500">
                Available
              </span>
              <h3 className="text-xl font-semibold text-neutral-300 hover:text-white">
                Counter-Strike 2
              </h3>
              <p className="mt-3 text-neutral-500">
                Our software is designed to enhance your gameplay by offering
                various customizable features to your assistance.
              </p>
            </div>
            <div className="mt-auto flex border-t divide-x border-neutral-700 divide-neutral-700">
              <button
                className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-es-xl bg-white shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none bg-white/10 hover:bg-white/20 border-neutral-700 text-white"
                disabled=""
                aria-haspopup="dialog"
                aria-expanded="false"
                aria-controls="hs-bg-gray-on-hover-cards"
                data-hs-overlay="#hs-bg-gray-on-hover-cards"
              >
                <IconInfoCircleFilled size={18} className="mr-[-0.25rem]" />
                More info
              </button>

              <div
                id="hs-bg-gray-on-hover-cards"
                className="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto pointer-events-none"
                role="dialog"
                tabIndex="-1"
                aria-labelledby="hs-bg-gray-on-hover-cards-label"
              >
                <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-4xl sm:w-full m-3 h-[calc(100%-3.5rem)] sm:mx-auto">
                  <div className="max-h-full overflow-hidden flex flex-col border shadow-sm rounded-xl pointer-events-auto bg-neutral-900 border-neutral-800 shadow-neutral-700/70">
                    <div className="flex justify-between items-center py-3 px-4 border-b border-neutral-800">
                      <h3
                        id="hs-bg-gray-on-hover-cards-label"
                        className="font-bold text-neutral-200"
                      >
                        <span className="bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                          eclypse.cc
                        </span>{" "}
                        for Counter-Strike 2
                      </h3>
                      <button
                        type="button"
                        className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent focus:outline-none disabled:opacity-50 disabled:pointer-events-none bg-neutral-700 hover:bg-neutral-600 text-neutral-400 focus:bg-neutral-600"
                        aria-label="Close"
                        data-hs-overlay="#hs-bg-gray-on-hover-cards"
                      >
                        <span className="sr-only">Close</span>
                        <IconX size={20} />
                      </button>
                    </div>

                    <div className="p-4 overflow-y-auto">
                      <div className="sm:divide-y divide-neutral-700">
                        <div className="py-3 sm:py-6">
                          <h4 className="mb-2 text-xs font-semibold uppercase text-neutral-400">
                            Features
                          </h4>

                          <div className="grid gap-2 sm:grid-cols-2 md:grid-cols-3">
                            <span className="p-4 transition duration-300 rounded-lg focus:outline-none bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800">
                              <div className="flex gap-x-6">
                                <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl">
                                  <IconTarget size={30} color="lightgray" />
                                </div>

                                <div className="grow">
                                  <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                    Humanized Aimbot
                                  </h3>
                                  <p className="mt-1 text-sm text-neutral-500">
                                    The best and most humanlike aimbot on the
                                    market.
                                  </p>
                                </div>
                              </div>
                            </span>

                            <span className="p-4 transition duration-300 rounded-lg focus:outline-none bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800">
                              <div className="flex gap-x-6">
                                <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl">
                                  <IconChartDots3 size={30} color="lightgray" />
                                </div>

                                <div className="grow">
                                  <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                    Recoil Control System
                                  </h3>
                                  <p className="mt-1 text-sm text-neutral-500">
                                    Control your recoil like a real pro with our
                                    RCS.
                                  </p>
                                </div>
                              </div>
                            </span>

                            <span className="p-4 transition duration-300 rounded-lg focus:outline-none bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800">
                              <div className="flex gap-x-6">
                                <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl">
                                  <IconCrosshair size={30} color="lightgray" />
                                </div>

                                <div className="grow">
                                  <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                    Smart Triggerbot
                                  </h3>
                                  <p className="mt-1 text-sm text-neutral-500">
                                    Improve your reaction time with our precise
                                    triggerbot.
                                  </p>
                                </div>
                              </div>
                            </span>

                            <span className="p-4 transition duration-300 rounded-lg focus:outline-none bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800">
                              <div className="flex gap-x-6">
                                <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl">
                                  <IconEye size={30} color="lightgray" />
                                </div>

                                <div className="grow">
                                  <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                    Player ESP {""}
                                    <span className="ms-1 inline bg-blue-50 border text-[.6125rem] leading-4 uppercase rounded-full py-0.5 px-2 bg-blue-900/70 border-blue-700 text-blue-500">
                                      Updated ✨
                                    </span>
                                  </h3>
                                  <p className="mt-1 text-sm text-neutral-500">
                                    Know your enemy's location at all times.
                                  </p>
                                </div>
                              </div>
                            </span>

                            <span className="p-4 transition duration-300 rounded-lg focus:outline-none bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800">
                              <div className="flex gap-x-6">
                                <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl">
                                  <IconLock size={30} color="lightgray" />
                                </div>

                                <div className="grow">
                                  <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                    Highest level of security
                                  </h3>
                                  <p className="mt-1 text-sm text-neutral-500">
                                    Never worry about getting banned again.
                                  </p>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>

                        <div className="py-3 sm:py-6">
                          <h4 className="mb-2 text-xs font-semibold uppercase text-neutral-400">
                            Supported Anti-Cheats
                          </h4>

                          <div className="grid gap-2 sm:grid-cols-2 md:grid-cols-3">
                            <a
                              className="p-4 transition duration-300 rounded-lg focus:outline-none bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800"
                              href="https://help.steampowered.com/en/faqs/view/571A-97DA-70E9-FF74"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="flex gap-x-6">
                                <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl w-10 h-10">
                                  <img
                                    className="rounded-lg"
                                    src="/assets/vac.png"
                                    alt=""
                                  ></img>
                                </div>

                                <div className="grow">
                                  <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                    VAC & VACnet
                                  </h3>
                                  <p className="mt-1 text-sm text-neutral-500">
                                    Stay undetected on Valve servers.
                                  </p>
                                </div>
                              </div>
                            </a>

                            <a
                              className="p-4 transition duration-300 rounded-lg focus:outline-none bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800"
                              href="https://easy.ac"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="flex gap-x-6">
                                <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl w-10 h-10">
                                  <img
                                    className="rounded-lg"
                                    src="/assets/eac.png"
                                    alt=""
                                  ></img>
                                </div>

                                <div className="grow">
                                  <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                    EasyAntiCheat
                                  </h3>
                                  <p className="mt-1 text-sm text-neutral-500">
                                    Play without fear even on the most advanced
                                    Anti-Cheats.
                                  </p>
                                </div>
                              </div>
                            </a>

                            <a
                              className="p-4 transition duration-300 rounded-lg focus:outline-none bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800"
                              href="https://esportal.com"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="flex gap-x-6">
                                <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl w-10 h-10">
                                  <img
                                    className="rounded-lg"
                                    src="/assets/esportal.jpg"
                                    alt=""
                                  ></img>
                                </div>

                                <div className="grow">
                                  <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                    ESPORTAL
                                  </h3>
                                  <p className="mt-1 text-sm text-neutral-500">
                                    Work your way up the ranks with our cheats.
                                  </p>
                                </div>
                              </div>
                            </a>

                            <a
                              className="p-4 transition duration-300 rounded-lg focus:outline-none bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800"
                              href="https://akros.ac"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="flex gap-x-6">
                                <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl w-10 h-10">
                                  <img
                                    className="rounded-lg"
                                    src="/assets/akros.jpg"
                                    alt=""
                                  ></img>
                                </div>

                                <div className="grow">
                                  <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                    Akros Anti-Cheat{" "}
                                    <span className="ms-1 inline bg-blue-50 border text-[.6125rem] leading-4 uppercase rounded-full py-0.5 px-2 bg-green-900/70 border-green-700 text-green-500">
                                      New
                                    </span>
                                  </h3>
                                  <p className="mt-1 text-sm text-neutral-500">
                                    Facing challenges have never been easier.
                                  </p>
                                </div>
                              </div>
                            </a>

                            <a
                              className="p-4 transition duration-300 rounded-lg focus:outline-none bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800"
                              href="https://gameguard.ac"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <div className="flex gap-x-6">
                                <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl w-10 h-10">
                                  <img
                                    className="rounded-lg"
                                    src="/assets/fastcup.png"
                                    alt=""
                                  ></img>
                                </div>

                                <div className="grow">
                                  <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                    GameGuard
                                  </h3>
                                  <p className="mt-1 text-sm text-neutral-500">
                                    Play FastCups like never before.
                                  </p>
                                </div>
                              </div>
                            </a>

                            <span className="p-4 transition duration-300 rounded-lg focus:outline-none bg-neutral-900 hover:bg-neutral-800 focus:bg-neutral-800">
                              <div className="flex gap-x-6">
                                <div className="mt-1.5 flex justify-center shrink-0 rounded-s-xl">
                                  <IconSpeakerphone
                                    size={"2.5rem"}
                                    stroke={1.5}
                                    color="lightgray"
                                  />
                                </div>

                                <div className="grow">
                                  <h3 className="text-sm font-semibold bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                                    More...{" "}
                                    <span className="ms-1 inline bg-blue-50 border text-[.6125rem] leading-4 uppercase rounded-full py-0.5 px-2 bg-orange-900/70 border-orange-700 text-orange-500">
                                      Coming soon
                                    </span>
                                  </h3>
                                  <p className="mt-1 text-sm text-neutral-500">
                                    Stay tuned for more in the future!
                                  </p>
                                </div>
                              </div>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    {ctx.user.has_sub ? (
                      <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t border-neutral-800">
                        <button
                          className="inline-flex items-center gap-x-1 text-sm decoration-2 hover:underline focus:outline-none focus:underline font-medium text-blue-500"
                          onClick={redirectToSetup}
                        >
                          Installation Guide
                          <IconChevronRight size={16} />
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <button
                className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-ee-xl bg-gradient-to-tl from-blue-600 to-violet-600 shadow-md shadow-transparent hover:shadow-blue-700/50 disabled:opacity-50 disabled:pointer-events-none bg-neutral-900 border-neutral-700 text-white hover:bg-neutral-800"
                disabled=""
                aria-haspopup="dialog"
                aria-expanded="false"
                aria-controls="hs-cookies"
                data-hs-overlay="#hs-cookies"
                onClick={() => setToggle(true)}
              >
                <IconCoinFilled size={18} className="mr-[-0.25rem]" />
                Purchase
              </button>

              <div
                id="hs-cookies"
                className="hs-overlay hidden size-full fixed top-0 start-0 z-[80] overflow-x-hidden overflow-y-auto"
                role="dialog"
                tabIndex="-1"
                aria-labelledby="hs-cookies-label"
              >
                <div className="hs-overlay-open:mt-7 hs-overlay-open:opacity-100 hs-overlay-open:duration-500 mt-0 opacity-0 ease-out transition-all sm:max-w-lg sm:w-full m-3 sm:mx-auto">
                  <div className="relative flex flex-col shadow-lg rounded-xl bg-neutral-900">
                    <div className="absolute top-2 end-2">
                      <button
                        type="button"
                        className="size-8 inline-flex justify-center items-center gap-x-2 rounded-full border border-transparent focus:outline-none disabled:opacity-50 disabled:pointer-events-none bg-neutral-700 hover:bg-neutral-600 text-neutral-400 focus:bg-neutral-600"
                        aria-label="Close"
                        data-hs-overlay="#hs-cookies"
                      >
                        <span className="sr-only">Close</span>
                        <IconX size={20} />
                      </button>
                    </div>
                    {toggle ? (
                      <>
                        <div className="p-4 sm:px-14 sm:pt-14 text-center overflow-y-auto">
                          <img
                            className="h-full object-cover rounded-xl w-[100%]"
                            src="/assets/cs2.jpeg"
                            alt="Counter-Strike 2"
                          />
                          <h3
                            id="hs-cookies-label"
                            className="mb-2 mt-6 text-2xl font-bold text-neutral-200"
                          >
                            <span className="bg-gradient-to-tl from-blue-600 to-violet-600 bg-clip-text text-transparent">
                              eclypse.cc
                            </span>{" "}
                            for Counter-Strike 2
                          </h3>
                          <p className="text-neutral-500">{infoText}</p>
                        </div>

                        <div className="p-4 sm:px-14 text-center">
                          <select
                            onChange={(e) =>
                              setSelectedDuration(e.target.value)
                            }
                            data-hs-select='{
                          "placeholder": "Subscription length...",
                          "toggleTag": "<button type=\"button\" aria-expanded=\"false\"></button>",
                          "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 ps-4 pe-9 flex gap-x-2 text-nowrap w-full cursor-pointer border rounded-lg text-start text-sm bg-neutral-900 border-neutral-700 text-neutral-400 focus:outline-none focus:ring-1 focus:ring-neutral-600",
                          "dropdownClasses": "mt-2 z-50 w-full max-h-72 p-1 space-y-0.5 border rounded-lg overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:w-2 [&::-webkit-scrollbar-thumb]:rounded-full [&::-webkit-scrollbar-track]:bg-neutral-700 [&::-webkit-scrollbar-thumb]:bg-neutral-500 bg-neutral-900 border-neutral-700",
                          "optionClasses": "py-2 px-4 w-full text-sm cursor-pointer rounded-lg focus:outline-none hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 bg-neutral-900 hover:bg-neutral-800 text-neutral-200 focus:bg-neutral-800",
                          "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"shrink-0 size-3.5 text-blue-500 \" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>",
                          "extraMarkup": "<div class=\"absolute top-1/2 end-3 -translate-y-1/2\"><svg class=\"shrink-0 size-3.5 text-neutral-500 \" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><path d=\"m7 15 5 5 5-5\"/><path d=\"m7 9 5-5 5 5\"/></svg></div>"
                          }'
                            className="hidden"
                            defaultValue={0}
                          >
                            <option value="0">1 month — €29.99</option>
                            <option value="1">3 months — €69.99</option>
                            <option value="2">6 months — €149.99</option>
                            <option value="3">Lifetime — €249.99</option>
                          </select>
                        </div>

                        <div className="flex items-center">
                          <form
                            className="hidden"
                            action="https://www.paypal.com/cgi-bin/webscr"
                            method="post"
                          >
                            <input type="hidden" name="cmd" value="_s-xclick" />
                            <input
                              type="hidden"
                              name="hosted_button_id"
                              value={ppMap[selectedDuration]}
                            />
                            <input
                              type="hidden"
                              name="currency_code"
                              value="EUR"
                            />
                            <input
                              type="hidden"
                              name="custom"
                              value={ctx.user.token}
                            />
                            <input
                              ref={paypalObject}
                              type="image"
                              name="submit"
                              alt="Submit"
                            />
                          </form>
                          <button
                            className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-es-xl shadow-md shadow-transparent hover:shadow-blue-600/50 disabled:opacity-50 disabled:pointer-events-none bg-[#0079C1] border-neutral-700 text-white"
                            onClick={purchasePayPal}
                          >
                            <IconBrandPaypalFilled
                              size={18}
                              className="mr-[-0.25rem]"
                            />{" "}
                            PayPal
                          </button>
                          <button
                            className="w-full py-3 px-4 inline-flex justify-center items-center gap-x-2 text-sm font-semibold rounded-ee-xl shadow-md shadow-transparent hover:shadow-orange-500/50 disabled:opacity-50 disabled:pointer-events-none bg-[#F7931A] border-neutral-700 text-black"
                            onClick={() => setToggle(false)}>
                            <IconCoinBitcoinFilled
                              size={18}
                              className="mr-[-0.25rem]"
                            />{" "}
                            Crypto
                          </button>
                        </div>
                      </>
                    ) : (
                      <div className="p-4 sm:px-4 sm:pt-14 text-center overflow-y-auto">
                        <div className="flex justify-center">
                          <IconCurrencyBitcoin size={64} className="text-neutral-300" stroke={1.5} />
                          <IconCurrencyEthereum size={64} className="text-neutral-300" stroke={1.5} />
                          <IconCurrencyLitecoin size={64} className="text-neutral-300" stroke={1.5} />
                        </div>
                        <p className="text-neutral-500 my-4">
                          Send to one of the following addresses:
                        </p>
                        <p className="text-neutral-200 my-4">
                          Bitcoin (BTC):{" "}
                          <kbd className="min-h-[30px] inline-flex justify-center items-center py-1 px-1.5 bg-white border border-gray-200 font-mono text-sm text-gray-800 rounded-md dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200">
                            3CV49scDNhY759fugdFBwQCzwumhsh1A8e
                          </kbd>
                        </p>
                        <p className="text-neutral-200 my-4">
                          Ethereum (ETH):{" "}
                          <kbd className="min-h-[30px] inline-flex justify-center items-center py-1 px-1.5 bg-white border border-gray-200 font-mono text-sm text-gray-800 rounded-md dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200">
                            0x21B38DD27136CD72BFEFB58808e43C6D353fC41A
                          </kbd>
                        </p>
                        <p className="text-neutral-200 my-4">
                          Litecoin (LTC):{" "}
                          <kbd className="min-h-[30px] inline-flex justify-center items-center py-1 px-1.5 bg-white border border-gray-200 font-mono text-sm text-gray-800 rounded-md dark:bg-neutral-900 dark:border-neutral-700 dark:text-neutral-200">
                            MPM2maUFxeQjNEiVLY2VFd7NPtuydi8i2L
                          </kbd>
                        </p>
                        <p className="text-neutral-500 my-4">
                          Your subscription will be activated manually.
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <ProductCard {...productData.valorant} />

          <ProductCard {...productData.eft} />
        </div>
      </div>
    </div>
  );
};

export default Products;
