import { useNavigate, useOutletContext } from "react-router-dom";
import Products from "../components/Products";
import Dashboard from "../components/Dashboard";

const Store = () => {
  const ctx = useOutletContext();

  if (!ctx.loaded) return <></>;

  return (
    <>
      <Dashboard />

      <div className="flex justify-start p-2 bg-gradient-to-b from-violet-600/10 via-transparent">
        <div className="w-full h-full">
          <Products />
        </div>
      </div>
    </>
  );
};

export default Store;
