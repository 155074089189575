import { useEffect, useState } from "react";
import { Outlet, useFetcher } from "react-router-dom";
import * as API from "./api";

import "preline/preline";
import { HSStaticMethods } from "preline/preline";

import FingerprintJS from "@fingerprintjs/fingerprintjs";

const autoInit = () => {
  HSStaticMethods.autoInit();

  const mutationCallback = (mutationsList, observer) => {
    HSStaticMethods.autoInit();
  }

  const observer = new MutationObserver(mutationCallback);

  observer.observe(document.body, {
    attributes: true,
    childList: true,
    subtree: true
  });
}

const detectGPU = async () => {
  return new Promise((resolve) => {
    try {
      const canvas = document.createElement("canvas");
      const gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl");

      if (!gl) {
        canvas.remove();
        resolve(undefined);
        return;
      }

      const debugInfo = gl.getExtension("WEBGL_debug_renderer_info");

      if (debugInfo) {
        const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);
        resolve(renderer);
      } else {
        resolve(undefined);
      }
    } catch (e) {
      resolve(undefined);
    }
  });
};

const App = () => {
  const [visitorId, setVisitorId] = useState(undefined);

  const [loaded, setLoaded] = useState(false);
  const [user, setUser] = useState({});

  useEffect(() => {
    autoInit();

    (async () => {
      setLoaded(false);

      try {
        const fp = await FingerprintJS.load();
        const result = await fp.get();

        setVisitorId(result.visitorId);

        await API.fetchDiscordUser(setUser);
      } catch (error) { }

      setLoaded(true);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        if (loaded) {
          const gpu = await detectGPU();

          const isMobile = /iPhone|iPad|iPod|Android|Mobi/i.test(navigator.userAgent);
          const deviceType = isMobile ? "mobile" : "desktop";

          const [navigationEntry] = performance.getEntriesByType("navigation");
          const loadTime = (navigationEntry.loadEventEnd - navigationEntry.startTime).toFixed(0);

          const timezoneOffset = new Date().getTimezoneOffset();
          const timezone = -timezoneOffset / 60;

          const language = navigator.language || navigator.userLanguage;

          await API.sendAnalytics({
            "visitor_id": visitorId,
            "timestamp": Date.now(),
            "url": window.location.href,
            "user": user.hasOwnProperty("token") ? user.token : undefined,
            "ua": navigator.userAgent,
            "referrer": (document.referrer.length > 0) ? document.referrer : undefined,
            "language": language || undefined,
            "screen_info": `${window.screen.width}x${window.screen.height}`,
            "device_type": deviceType,
            "cpu_threads": navigator.hardwareConcurrency,
            "gpu": gpu || undefined,
            "load_time_ms": Number(loadTime),
            "timezone": {
              "name": Intl.DateTimeFormat().resolvedOptions().timeZone,
              "offset": timezone
            }
          });
        }
      } catch (error) { }
    })();
  }, [loaded]);

  if (!loaded) return <></>;

  return <Outlet context={{ visitorId, user, setUser, loaded, setLoaded }} />;
};

export default App;
