import { useEffect } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import Dashboard from "../components/Dashboard";
import Timeline from "../components/Timeline";

const Setup = () => {
  const ctx = useOutletContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (ctx.loaded && !ctx.user.has_sub) {
      navigate("/store");
    }
  }, [ctx]);

  if (!ctx.loaded) return <></>;

  return (
    <>
      <Dashboard />

      <div className="flex justify-start p-2 bg-gradient-to-b from-violet-600/10 via-transparent">
        <div className="max-w-[50rem] flex flex-col justify-center items-center mx-auto size-full">
          <Timeline />
        </div>
      </div>
    </>
  );
};

export default Setup;
